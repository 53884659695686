import React, { useEffect,useState } from "react";
import why1 from "../Images/Why1.png";
import why2 from "../Images/Why2.png";
import why3 from "../Images/Why3.png";
import why4 from "../Images/Why4.png";
import why5 from "../Images/Why5.png";
import AOS from "aos";
import "aos/dist/aos.css";
const WhyDubaiSecs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      offset: 200,
    });

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
  <div
    data-aos="fade-up"
    data-aos-delay="100"
    className="flex flex-col md:flex-row justify-center w-11/12 items-center shadow-2xl rounded-l-3xl my-10 md:my-20 mx-auto"
  >
    <div className="flex-1 p-4 md:pl-9">
      <h2 className="text-xl md:text-2xl font-bold mb-4">Glittering Skyline</h2>
      <p className="text-sm md:text-base">
        Picture yourself amid the towering skyline, where innovation
        intertwines with tradition in a captivating harmony. Dubai's
        steadfast political stability, combined with its investor-friendly
        policies and commitment to sustainable progress, invites dreamers
        and pioneers to join its journey toward prosperity. Within this
        vibrant oasis, where dreams materialize, investing in Dubai
        transcends mere financial transactions—it's an immersive odyssey
        into a realm brimming with boundless opportunities and endless
        potential. Embrace the allure of a city where ambition knows no
        bounds, and every investment propels extraordinary visions toward
        reality. Welcome to Dubai, where the skyline isn't just a view but a
        canvas for dreams to flourish.
      </p>
    </div>

    <div className="flex-1 p-4 md:ml-8">
      <img src={why1} alt="Glittering Skyline" className="max-w-full rounded-lg" />
    </div>
  </div>

  <div
    data-aos="fade-up"
    data-aos-delay="100"
    className="flex flex-col md:flex-row-reverse justify-center w-11/12 items-center shadow-2xl rounded-r-3xl my-10 md:my-20 mx-auto"
  >
    <div className="flex-1 p-4 md:pl-9 md:pr-3">
      <h2 className="text-xl md:text-2xl font-bold mb-4">Best Beaches</h2>
      <p className="text-sm md:text-base">
        Imagine pristine beaches stretching along the azure coastline, offering
        a tranquil retreat amidst the bustling cityscape. With its stable
        political landscape, investor-friendly policies, and commitment to
        sustainable growth, Dubai invites dreamers and visionaries to partake in
        its journey towards prosperity. In this dynamic oasis, where dreams are
        transformed into reality, investing in Dubai isn't just a financial
        endeavor—it's an invitation to embark on a magical adventure of limitless
        possibilities, where the allure of the Arabian Gulf's crystal-clear
        waters meets the allure of boundless opportunity.
      </p>
    </div>

    <div className="flex-1 p-4">
      <img src={why2} alt="Best Beaches" className="max-w-full rounded-lg" />
    </div>
  </div>

  <div
    data-aos="fade-up"
    data-aos-delay="100"
    className="flex flex-col md:flex-row justify-center w-11/12 items-center shadow-2xl rounded-l-3xl my-10 md:my-20 mx-auto"
  >
    <div className="flex-1 p-4 md:pl-9">
      <h2 className="text-xl md:text-2xl font-bold mb-4">Outstanding School</h2>
      <p className="text-sm md:text-base">
        Dubai boasts a world-class education sector, adding to its allure. With
        internationally recognized schools and universities, families access
        exceptional educational opportunities. From prestigious international
        schools to specialized academies, Dubai caters to diverse academic
        needs. Imagine your children thriving in an environment of academic
        excellence, cultural diversity, and innovation. Dubai's educational
        institutions offer state-of-the-art facilities and highly qualified
        educators, nurturing young minds for success. With a focus on holistic
        development and 21st-century skills, students gain tools for academic,
        social, and professional excellence.
      </p>
    </div>

    <div className="flex-1 p-4 md:ml-8">
      <img src={why3} alt="Outstanding School" className="max-w-full rounded-lg" />
    </div>
  </div>

  <div
    data-aos="fade-up"
    data-aos-delay="100"
    className="flex flex-col md:flex-row-reverse justify-center w-11/12 items-center shadow-2xl rounded-r-3xl my-10 md:my-20 mx-auto"
  >
    <div className="flex-1 p-4 md:pl-9 md:pr-3">
      <h2 className="text-xl md:text-2xl font-bold mb-4">World’s Best Malls</h2>
      <p className="text-sm md:text-base">
        Dubai, the epitome of luxury living, entices investors with its
        glamorous shopping experiences. Picture yourself amidst extravagant malls
        adorned with designer boutiques, shimmering with opulence, and vibrant
        souks brimming with exotic treasures. From the iconic Dubai Mall, a haven
        of high-end retailers and entertainment, to traditional souks exuding
        Arabian charm, Dubai promises an unmatched shopping extravaganza.
      </p>
    </div>

    <div className="flex-1 p-4">
      <img src={why4} alt="World’s Best Malls" className="max-w-full rounded-lg" />
    </div>
  </div>

  <div
    data-aos="fade-up"
    data-aos-delay="100"
    className="flex flex-col md:flex-row justify-center w-11/12 items-center shadow-2xl rounded-l-3xl my-10 md:my-20 mx-auto"
  >
    <div className="flex-1 p-4 md:pl-9">
      <h2 className="text-xl md:text-2xl font-bold mb-4">To Die For Restaurants</h2>
      <p className="text-sm md:text-base">
        Dubai's allure as an investment destination extends to its glamorous
        dining scene, where culinary excellence meets luxurious ambiance. Imagine
        dining in world-renowned restaurants led by Michelin-starred chefs
        against breathtaking skylines. From opulent fine dining to vibrant street
        food markets, Dubai tantalizes discerning diners with flavors from around
        the globe.
      </p>
    </div>

    <div className="flex-1 p-4 md:ml-8">
      <img src={why5} alt="To Die For Restaurants" className="max-w-full rounded-lg" />
    </div>
  </div>
</div>

  );
};

export default WhyDubaiSecs;
