import React, { useState } from "react";
import HeroSection from "../WhyDubaiComps/HeroSection";
import WhyDubaiSecs from "../WhyDubaiComps/WhyDubaiSecs";
import Footer from "../WhyDubaiComps/Footer";
import useScrollToTop from "../Scroll";

const WhyDubai = () => {
  useScrollToTop();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      <HeroSection />
      <div className="my-10 text-center">
        Dubai's allure as an investment haven lies in its enchanting blend of
        strategic location, flourishing economy, and visionary <br />{" "}
        development. Nestled at the crossroads of the world, this cosmopolitan
        gem beckons investors with its promise of boundless
        <br /> opportunities and unparalleled luxury. From its iconic skyline
        adorned with architectural marvels to its vibrant cultural scene and
        <br /> world-class amenities, Dubai embodies the epitome of modern
        opulence.
      </div>
      <WhyDubaiSecs />
      <Footer />
    </div>
  );
};

export default WhyDubai;
