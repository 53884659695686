import React, { useState } from "react";
import Modal from "react-modal";
import Tilal1 from "../Images/Downtown1.jpg";
import Tilal2 from "../Images/Downtown2.jpg";
import Tilal3 from "../Images/Downtown3.jpg";

Modal.setAppElement("#root"); // Ensure this line is present to set the root app element for accessibility

const HeroSection = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [Tilal1, Tilal2, Tilal3];

  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const handleNext = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentImage(
      (prevImage) => (prevImage + images.length - 1) % images.length
    );
  };

  return (
    <div className="flex  h-[80vh] my-10 w-11/12 mx-auto space-x-4">
      <div className="flex-1">
        <img
          src={images[0]}
          alt="Main"
          className="object-cover w-full h-full cursor-pointer"
          onClick={() => openImageViewer(0)}
        />
      </div>
      <div className="flex flex-col flex-1 space-y-4">
        <img
          src={images[1]}
          alt="First"
          className="object-cover w-full h-1/2 cursor-pointer"
          onClick={() => openImageViewer(1)}
        />
        <img
          src={images[2]}
          alt="Second"
          className="object-cover w-full h-1/2 cursor-pointer"
          onClick={() => openImageViewer(2)}
        />
      </div>

      {isViewerOpen && (
        <Modal
          isOpen={isViewerOpen}
          onRequestClose={closeImageViewer}
          contentLabel="Image Viewer"
          className="flex items-center justify-center " // Centered alignment for modal content
          style={{
            content: {
              position: "relative",
              borderColor: "white",
              borderBlock: "1px",
              inset: "auto",
              width: "60vw",
              height: "80vh",
              margin: "auto",
              border: "none",
              background: "none",
              padding: "none",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            overlay: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
            },
          }}
          ariaHideApp={false}
        >
          <div className="relative flex justify-center items-center h-full w-full">
            <button
              className="absolute top-4 right-4 text-3xl text-white bg-gray-800 px-2 py-1 rounded"
              onClick={closeImageViewer}
            >
              &#x2715; {/* Cross sign */}
            </button>
            <button
              className="absolute left-4 text-white text-3xl bg-gray-800 px-2 py-1 rounded"
              onClick={handlePrevious}
            >
              &#x276E; {/* Left arrow */}
            </button>
            <img
              src={images[currentImage]}
              alt={`Slide ${currentImage}`}
              className="object-contain max-h-full max-w-full"
            />
            <button
              className="absolute right-4 text-white text-3xl bg-gray-800 px-2 py-1 rounded"
              onClick={handleNext}
            >
              &#x276F; {/* Right arrow */}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HeroSection;
