import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../WhyDubaiComps/Footer";
import Logo from "../../Images/Logo.png";
import Detail1 from "../../Images/Detail10.png";
import blogcard1 from "../../Images/blogcard1.png";
import blogcard2 from "../../Images/blogcard2.png";
import useScrollToTop from "../../Scroll";
import blogcard3 from "../../Images/blogcard3.png";
const Card = ({ image, title, text }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden p-4 flex flex-col justify-between h-full">
    <div>
      <img
        className="w-full h-48 object-cover rounded-md"
        src={image}
        alt={title}
      />
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="text-gray-600 mt-2">{text}</p>
    </div>
  </div>
);
const Page10 = () => {
  useScrollToTop();

  const cards = [
    {
      image: blogcard1,
      title:
        "Dubai Real Estate Market Witnesses a 27% Surge in Transactions During February",
      text: "The most fascinating aspect of this tale of growth and development was witnessed in Dubai’s real estate market in February this year",
    },
    {
      image: blogcard2,
      title: "Dubai Leads Global Prime Residential Market Growth in 2024",
      text: "The study on the share of global prime residential market growth in the year 2024 has revealed that Dubai has outperformed all competitors in 2023.",
    },
    {
      image: blogcard3,
      title:
        "Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values",
      text: "In January 2024, there was an increase in the price of apartments in Dubai, which showed the highest growth in the last ten years, according to ValuStrat Price Index (VPI).",
    },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <div className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </div>
            <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-slate-300 text-white py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
          <div className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </div>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        {menuOpen && (
          <ul className="bg-slate-300 text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>  <div className=" mx-auto flex items-center flex-col mt-32">
        <img src={Detail1} alt="" />
        <div className="w-10/12">
          <p className="mt-20 font-semibold">
            Technology is revolutionizing Dubai's real estate market, with smart
            homes leading the charge. These technologically advanced residences
            offer a range of features, including automated lighting, climate
            control, security systems, and energy management, all controlled via
            smartphones or voice assistants. The convenience and efficiency
            provided by these smart home systems are attracting a growing number
            of buyers and tenants, driving demand in the real estate market.
          </p>

          <p className="my-10 font-semibold">
            The adoption of smart home technology is not limited to luxury
            properties. Developers are increasingly incorporating these features
            into mid-range and affordable housing projects, making them
            accessible to a wider audience. This democratization of smart
            technology is expected to accelerate its adoption, transforming
            Dubai's housing landscape. Properties equipped with smart technology
            are also perceived as future-proof investments, appealing to
            tech-savvy buyers and investors.
          </p>

          <p className="my-10 font-semibold">
            Beyond smart homes, technology is playing a crucial role in the real
            estate transaction process. Virtual reality (VR) and augmented
            reality (AR) are being used to offer virtual property tours,
            allowing buyers to explore properties remotely. This innovation has
            become particularly valuable during the COVID-19 pandemic, enabling
            continued property transactions despite travel restrictions. VR and
            AR technologies are also enhancing the marketing of properties,
            providing immersive experiences that attract potential buyers.
          </p>

          <p className="my-10 font-semibold">
            Blockchain technology is another game-changer for Dubai's real
            estate market. The Dubai Land Department (DLD) has been a pioneer in
            adopting blockchain for property transactions, ensuring greater
            transparency, security, and efficiency. Blockchain eliminates the
            need for intermediaries, reducing transaction costs and time. This
            technological advancement is streamlining the buying and selling
            process, making it more attractive for both local and international
            investors.
          </p>

          <p className="my-10 font-semibold">
            Big data and artificial intelligence (AI) are transforming real
            estate market analysis and decision-making. AI algorithms analyze
            vast amounts of data to provide insights into market trends,
            property valuations, and investment opportunities. Real estate
            companies are leveraging these technologies to offer personalized
            recommendations to clients, enhancing the customer experience. This
            data-driven approach is helping investors make informed decisions,
            minimizing risks and maximizing returns.
          </p>

          <p className="my-10 font-semibold">
            Looking ahead, the integration of technology in Dubai's real estate
            market is set to deepen. The development of smart cities, with
            interconnected infrastructure and IoT (Internet of Things) systems,
            will further enhance the living experience. These advancements will
            not only improve the quality of life for residents but also boost
            the attractiveness of Dubai as a high-tech, modern city, drawing
            more investors and residents to its real estate market.
          </p>
        </div>
      </div>
      <div className="w-4/5 my-10 mx-auto ">
        <h1 className="font-bold text-3xl underline">Related Articles</h1>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 max-w-screen-2xl">
            {cards.map((card, index) => (
              <Card
                key={index}
                image={card.image}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page10;
