import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../WhyDubaiComps/Footer";
import Logo from "../../Images/Logo.png";
import Detail1 from "../../Images/Detail2.png";
import blogcard1 from "../../Images/blogcard1.png";
import blogcard2 from "../../Images/blogcard2.png";
import useScrollToTop from "../../Scroll";
import blogcard3 from "../../Images/blogcard3.png";
const Card = ({ image, title, text }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden p-4 flex flex-col justify-between h-full">
    <div>
      <img
        className="w-full h-48 object-cover rounded-md"
        src={image}
        alt={title}
      />
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="text-gray-600 mt-2">{text}</p>
    </div>
  </div>
);
const Page2 = () => {
  useScrollToTop();

  const cards = [
    {
      image: blogcard1,
      title:
        "Dubai Real Estate Market Witnesses a 27% Surge in Transactions During February",
      text: "The most fascinating aspect of this tale of growth and development was witnessed in Dubai’s real estate market in February this year",
    },
    {
      image: blogcard2,
      title: "Dubai Leads Global Prime Residential Market Growth in 2024",
      text: "The study on the share of global prime residential market growth in the year 2024 has revealed that Dubai has outperformed all competitors in 2023.",
    },
    {
      image: blogcard3,
      title:
        "Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values",
      text: "In January 2024, there was an increase in the price of apartments in Dubai, which showed the highest growth in the last ten years, according to ValuStrat Price Index (VPI).",
    },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <div className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </div>
            <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-slate-300 text-white py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
          <div className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </div>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        {menuOpen && (
          <ul className="bg-slate-300 text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>  <div className=" mx-auto flex items-center flex-col mt-32">
        <img src={Detail1} alt="" />
        <div className="w-10/12">
          <p className="mt-20 font-semibold">
            The study on the share of global prime residential market growth in
            2024 has revealed that Dubai has outperformed all competitors in
            2023. Savills pointed out that the growth in the prime residential
            market in Dubai records an impressive increase of 17%. 4%, which
            marks an increase compared to the average of 2 percent. This is 2%
            less than what we have seen in other major cities across the globe. 
            This impressive growth path places Dubai on par with Sydney as it is
            marked for prime residential property growth in the coming year. The
            population growth is most positively related to the increased
            population of the affluent population group in both cities.
          </p>

          <p className="my-10 font-semibold">
            Savill’s forecast of performance in Dubai for 2024 suggests that the
            growth will continue to rise, with expected incremental boosts of
            between 4% and 5.9%. Also, the rental price in Dubai has also
            increased at a much higher rate of about 9% against a global annual
            average of 5.1%.  The first reveal gives a boost to the prime
            residential market by rising 17.4% on capital values and above the
            global adaptation average. This is useful and clearly evident where
            growth has been even higher during the first half of the year,
            underscoring Dubai’s leadership in the sector. Despite such
            phenomenal growth, Dubai’s market is still affordable at $850 per
            square foot and has many advantages, such as lower costs of living,
            a comparatively easier visa regime, and pleasant weather. Thus,
            investors, both international and local, are flocking in to invest
            more in the region.
          </p>

          <p className="my-10 font-semibold">
            While Dubai shines, other Asia-Pacific cities, notably Mumbai, also
            experienced substantial growth. However, cities like New York and
            San Francisco faced declines due to economic challenges. Hong Kong's
            market was particularly affected by political and economic
            instability, witnessing a decrease of 3.7% in capital values over
            the year.
          </p>

          <p className="my-10 font-semibold">
            Savills anticipates a modest overall growth of 0.6% across the 30
            cities surveyed in 2024. Dubai and Sydney's prime markets are
            expected to outperform others, driven by increasing high-net-worth
            populations and demand. Sydney faces a supply shortage, which is
            likely to further inflate prices, while Dubai's growth, though
            slowing, remains robust due to its appeal as a top lifestyle
            destination, supported by government visa reforms and high-quality
            developments.
          </p>

          <p className="my-10 font-semibold">
            Andrew Cummings of Savills Middle East highlighted Dubai's appeal,
            emphasizing its world-class infrastructure, safety, and security,
            which, alongside visa reforms, support strong market demand. Despite
            some bright spots, prime residential markets in cities like Los
            Angeles, New York, and London are predicted to face declines due to
            economic pressures and rising interest rates.
          </p>

          <p className="my-10 font-semibold">
            Sellers also commented on global political affairs and uncertainties
            about future possible cuts in interest rates, which provided
            indications of sharper activity and house prices in the last quarter
            of 2024.  Rental markets also demonstrated robustness, although
            Dubai was close to 10% up in rental prices. Globally, the rental
            market benchmark continues to perform well, as identified within
            Lisbon housing, which boasted a 39% rise.
          </p>
          <p className="my-10 font-semibold">
            Dubai also offers attractive investment yields of 4.8% with low
            transaction costs, further underscoring its position in the global
            prime residential market.
          </p>
        </div>
      </div>
      <div className="w-4/5 my-10 mx-auto ">
        <h1 className="font-bold text-3xl underline">Related Articles</h1>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 max-w-screen-2xl">
            {cards.map((card, index) => (
              <Card
                key={index}
                image={card.image}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page2;
