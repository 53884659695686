import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Community1 from "../Images/Community1.png";
import Community2 from "../Images/Community2.png";
import Community3 from "../Images/Community3.png";
import Community4 from "../Images/Community4.png";
import Community5 from "../Images/Community5.png";
import { Link } from "react-router-dom";

const imageData = [
  { src: Community1, text: "DownTown Dubai" },
  { src: Community2, text: "Dubai Hills Estate" },
  { src: Community3, text: "Dubai Marina" },
  { src: Community4, text: "Palm Jumeirah" },
  { src: Community5, text: "Business Bay" },
];

const Communities = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      offset: 200,
    });

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="p-6">
      <div className="text-center mb-6">
        <h2 className="text-3xl font-bold">Communities</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-6">
        {imageData.map((image, index) => (
          <div
            key={index}
            className="relative group"
            data-aos="fade-up"
            data-aos-delay={`${index * 200}`}
          >
            <Link to="/communities#top">
              <img
                src={image.src}
                alt={image.text}
                className="w-full h-auto object-cover rounded-lg"
                style={{ minHeight: "200px" }} // Ensuring the minimum height
              />
              <div className="absolute inset-0 flex mt-10 justify-center transition-opacity rounded-lg">
                <span className="text-white text-lg font-bold">
                  {image.text}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div className="flex justify-center py-10">
        <Link
          to={"/communities#top"}
          type="button"
          className="text-white bg-[#D8B56D] w-[20%] font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          Explore all
        </Link>
      </div>
    </section>
  );
};

export default Communities;
