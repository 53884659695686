import React, { useState } from "react";

const Map = () => {
  return (
    <div className="w-3/4 mx-auto my-10">
      <h1 className="text-3xl font-semibold text-center my-8">
        Head Office Locations
      </h1>
      <div class="relative w-full h-96">
        <iframe
          title="map"
          class="absolute top-0 left-0 w-full h-full  rounded-2xl"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.168505642546!2d55.173861210957384!3d25.096156677683474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b798cbfff8b%3A0x70a7f5e105a580e!2sDAMAC%20Smart%20Heights!5e0!3m2!1sen!2sus!4v1728393772385!5m2!1sen!2sus"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
