import React, { useState } from "react";
import Navbar from "../BlogComponents/Navbar";
import BlogCars from "../BlogComponents/BlogCars";
import Blog4 from "../Images/blog4.png";
import Footer from "../WhyDubaiComps/Footer";
const Blogs = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <Navbar />
      <div className="w-11/12 mx-auto rounded-xl mt-10 bg-[#EDEDED]">
        {/* <div className=" py-10 flex space-x-10 p-4">
          <div className="bg-customGray w-3/5 ml-12  border-customBorder border-2  p-2 flex items-center">
            <input
              type="text"
              placeholder="Search"
              className="bg-transparent text-black border-[#A8A8A8] w-full  focus:outline-none"
            />
          </div>
          <div className="bg-customGray border w-1/5 border-customBorder justify-center p-2 flex items-center">
            <button className="bg-[#D8B56D] text-white uppercasebg-transparent  focus:outline-none">
              Categories
            </button>
          </div>
        </div> */}
        <BlogCars />
      </div>
      
      <Footer />
    </div>
  );
};

export default Blogs;
