import React, { useState } from "react";
import Banner from "../Images/Footer.png";
import Logo from "../Images/Logo.png";
import Linkedin from "../Images/Linkedin.png";
import fb from "../Images/Facebook.png";
import google from "../Images/google.png";
import mail from "../Images/mail.png";
import phone from "../Images/phone.png";
import location from "../Images/location.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <div
        className="w-full h-[40vh] bg-center bg-cover"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <div className="w-11/12 mx-auto h-full py-5">
          <div className="flex flex-col md:flex-row justify-between">
            {/* Logo and Social Icons */}
            <div className="mb-6 md:mb-0">
              <img className="w-28" src={Logo} alt="Logo" />
              <div className="flex mt-4 space-x-4">
                <img src={fb} alt="Facebook" className="w-6 h-6" />
                <img src={google} alt="Google" className="w-6 h-6" />
                <img src={Linkedin} alt="LinkedIn" className="w-6 h-6" />
              </div>
            </div>

            {/* Quick Links */}
            <div className="mb-6 md:mb-0">
              <ul>
                <li className="font-semibold text-xl mb-2">Quick Links</li>
                <li>
                  <Link to={"/developers#top"}>Search for Property</Link>
                </li>
                <li>
                  <Link to={"/offplan"}>Off Plan Properties</Link>
                </li>
                <li>
                  <Link to={"/about"}>About Us</Link>
                </li>
                <li>
                  <Link to={"/communities"}>Community</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contact</Link>
                </li>
              </ul>
            </div>

            {/* Services */}
            <div className="mb-6 md:mb-0">
              <ul>
                <li className="font-semibold text-xl mb-2">Services</li>
                <li>Effortless Property Management</li>
                <li>Property Search and Selection</li>
                <li>Investment Consultancy</li>
                <li>Home Inspection</li>
                <li>Home Snagging</li>
              </ul>
            </div>

            {/* Contact Information */}
            <div className="mb-6 md:mb-0">
              <ul>
                <li className="font-semibold text-xl mb-2">Contact</li>
                <li className="flex mt-3">
                  <img className="mr-2 w-5 h-5" src={location} alt="Location" />
                  1908 DAMAC Smart Heights - Barsha Heights
                </li>
                <li className="flex mt-3">
                  <img className="mr-2 w-5 h-5" src={phone} alt="Phone" />
                  +971588098415
                </li>
                <li className="flex mt-3">
                  <img className="mr-2 w-5 h-5" src={mail} alt="Email" />
                  marketing@pointxrealty.com
                </li>
              </ul>
            </div>
          </div>
          <hr className="h-[3px] my-4 bg-black" />
          <div className="flex flex-col md:flex-row justify-between text-center md:text-left">
            <div className="mb-4 md:mb-0">© 2024 All Rights Reserved.</div>
            <div className="flex justify-center md:justify-end space-x-4">
              <Link to="#" className="border-r-2 border-black pr-4">
                Terms Of Use
              </Link>
              <Link to="#">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
