import React, { useState } from "react";
import blogcard1 from "../Images/blogcard1.png";
import blogcard2 from "../Images/blogcard2.png";
import blogcard3 from "../Images/blogcard3.png";
import blogcard4 from "../Images/blogcard4.png";
import blogcard5 from "../Images/blogcard5.png";
import blogcard6 from "../Images/blogcard6.png";
import blogcard7 from "../Images/blogcard7.png";
import blogcard8 from "../Images/blogcard8.png";
import blogcard9 from "../Images/blogcard9.png";
import blogcard10 from "../Images/blogcard10.png";
import blogcard11 from "../Images/blogcard11.png";
import blogcard12 from "../Images/blogcard12.png";
import blogcard13 from "../Images/blogcard13.png";
import blogcard14 from "../Images/blogcard14.png";
import { Link } from "react-router-dom";
const Card = ({ image, title, text, link }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden p-4 flex flex-col justify-between h-full">
    <div>
      <img
        className="w-full h-48 object-cover rounded-md"
        src={image}
        alt={title}
      />
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="text-gray-600 mt-2">{text}</p>
    </div>
    <div className="text-right mt-4">
      <Link to={link} className="text-blue-500 hover:text-blue-700">
        Read More
      </Link>
    </div>
  </div>
);

const CardGrid = () => {
  const cards = [
    {
      image: blogcard1,
      title:
        "Dubai Real Estate Market Witnesses a 27% Surge in Transactions During February",
      text: "The most fascinating aspect of this tale of growth and development was witnessed in Dubai’s real estate market in February this year",
      link: "/blogdetail1#top",
    },
    {
      image: blogcard2,
      title: "Dubai Leads Global Prime Residential Market Growth in 2024",
      text: "The study on the share of global prime residential market growth in the year 2024 has revealed that Dubai has outperformed all competitors in 2023.",
      link: "/blogdetail2#top",
    },
    {
      image: blogcard3,
      title:
        "Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values",
      text: "In January 2024, there was an increase in the price of apartments in Dubai, which showed the highest growth in the last ten years, according to ValuStrat Price Index (VPI).",
      link: "/blogdetail3#top",
    },
    {
      image: blogcard4,
      title:
        "Dubai's Property Investment Dilemma: Choosing Between Townhouses and Villas in 2024",
      text: "Apartments are no doubt great, but villas stand for the real luxury of living in Dubai and the ease of accessing some of the best neighborhoods and service providers",
      link: "/blogdetail4#top",
    },
    {
      image: blogcard5,
      title: "Dubai's Property Market Trends Towards Stability in 2024",
      text: "It is convenient now to say that, after the three consecutive years of increase, the market for properties in Dubai may experience some fluctuation to a more stable level, with average prices of properties either rising slightly or remaining at the same level.",
      link: "/blogdetail5#top",
    },
    {
      image: blogcard6,
      title:
        "Understanding Dubai's Property Tax Landscape for New Real Estate Investors",
      text: "In the global comparative real estate market, another significant attribute of Dubai is the tax regime, which is still highly beneficial and still attracting international investors",
      link: "/blogdetail6#top",
    },
    {
      image: blogcard7,
      title:
        "Dubai: A Prime Destination for Affordable, High-Yield Real Estate Investments",
      text: "Dubai, the Jewel of the Middle East, is gradually responding to the allure of foreign investors who are willing to invest in this area as well as get reasonable yields from reasonably priced real estate stocks.",
      link: "/blogdetail7#top",
    },
    {
      image: blogcard8,
      title: "Sustainable Real Estate Development in Dubai",
      text: "The rise of sustainable real estate development in Dubai marks a significant shift towards environmentally friendly living spaces.",
      link: "/blogdetail8#top",
    },
    {
      image: blogcard9,
      title: "Impact of Expo 2020 on Dubai's Real Estate Market",
      text: "Expo 2020 has had a profound impact on Dubai's real estate market, catalyzing growth and attracting global attention. The event, which showcased innovations from around the world.",
      link: "/blogdetail9#top",
    },
    {
      image: blogcard10,
      title: "The Role of Technology in Dubai's Real Estate Market",
      text: "Technology is revolutionizing Dubai's real estate market, with smart homes leading the charge. These technologically advanced residences offer a range of features,",
      link: "/blogdetail10#top",
    },
    {
      image: blogcard11,
      title: "Comparing Freehold vs. Leasehold Properties in Dubai",
      text: "When investing in Dubai's real estate market, buyers often face the decision between freehold and leasehold properties.",
      link: "/blogdetail11#top",
    },
    {
      image: blogcard12,
      title: "The Future of Commercial Real Estate in Dubai",
      text: "Dubai's commercial real estate market is poised for growth, driven by a dynamic economy and strategic location. The city serves as a major business hub,",
      link: "/blogdetail12#top",
    },
    {
      image: blogcard13,
      title: "Dubai's Real Estate Riches: Your Guide to Investment Success",
      text: "The real estate market in Dubai is a magnet for investors because it is filled with lucrative opportunities. You can use the tools in this guide to maximize its potential and make money from investments.",
      link: "/blogdetail13#top",
    },
    {
      image: blogcard14,
      title:
        "Unveiling Your Dream Home: A Guide to Dubai's Real Estate Market with PointX Realty Dubai",
      text: " PointX Realty Dubai's Guide to Dubai's Real Estate Market Nonetheless, for the people who need a slice of the pie, it tends to be trying to explore the housing market.",
      link: "/blogdetail14#top",
    },
  ];

  return (
    <div className="flex justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 max-w-screen-2xl">
        {cards.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            title={card.title}
            text={card.text}
            link={card.link}
          />
        ))}
      </div>
    </div>
  );
};

export default CardGrid;
