import React, { useState } from "react";
import AboutHero from "../AboutComponents/AboutHero";
import Missions from "../AboutComponents/Missions";
import ContactForm from "../AboutComponents/ContactForm";
import Footer from "../WhyDubaiComps/Footer";
import useScrollToTop from "../Scroll";
const About = () => {
  useScrollToTop();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <div id="top">
        <AboutHero />
      </div>
      
      <Missions />
      <ContactForm />
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default About;
