import React from "react";
import Buy from "../Images/Buy.png";
import Sell from "../Images/Sell.png";
import Rent from "../Images/Rent.png";

const ServicesCard = () => {
  return (
    <div className="bg-[#f5f5f5cb] w-11/12 mx-auto my-10 p-4 flex flex-col md:flex-row justify-between">
      <div className="bg-[#fff] h-[450px] w-full md:w-[30%] flex flex-col items-center py-10 justify-between my-6 transform transition duration-500 hover:scale-105">
        <img src={Buy} alt="Buy a Home" className="w-1/2 md:w-auto" />
        <h1 className="font-semibold animate-fadeIn text-3xl text-center">Buy a Home</h1>
        <p className="text-[#7C8893] animate-fadeIn text-center px-4">
          Find your dream home with our expert guidance and personalized support at every step of the way.
        </p>
      </div>
      <div className="bg-[#fff] h-[450px] w-full md:w-[30%] flex flex-col items-center py-10 justify-between my-6 transform transition duration-500 hover:scale-105">
        <img src={Rent} alt="Rent a Home" className="w-1/2 md:w-auto" />
        <h1 className="font-semibold animate-fadeIn text-3xl text-center">Rent a Home</h1>
        <p className="text-[#7C8893] animate-fadeIn text-center px-4">
          Discover the ideal rental property tailored to your needs and preferences, backed by our commitment to excellence in property management.
        </p>
      </div>
      <div className="bg-[#fff] h-[450px] w-full md:w-[30%] flex flex-col items-center py-10 justify-between my-6 transform transition duration-500 hover:scale-105">
        <img src={Sell} alt="Sell a Home" className="w-1/2 md:w-auto" />
        <h1 className="font-semibold animate-fadeIn text-3xl text-center">Sell a Home</h1>
        <p className="text-[#7C8893] animate-fadeIn text-center px-4">
          Maximize the value of your property and enjoy a hassle-free selling experience with our comprehensive solutions.
        </p>
      </div>
    </div>
  );
};

export default ServicesCard;
