import React, { useState } from "react";
import HeroContact from "../ContactComponents/HeroContact";
import ContactForm from "../AboutComponents/ContactForm";
import AdressBox from "../ContactComponents/AdressBox";
import Map from "../ContactComponents/Map";
import Footer from "../WhyDubaiComps/Footer";
import useScrollToTop from "../Scroll";

const Contact = () => {
  useScrollToTop();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      <HeroContact />
      <ContactForm />
      <AdressBox />
      <Map />
      <Footer />
    </div>
  );
};

export default Contact;
