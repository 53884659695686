import React, { useState } from "react";

const ContactForm = () => {
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    const response = await fetch("https://formspree.io/f/xwpkpjea", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      setStatus("SUCCESS");
      form.reset();
    } else {
      setStatus("ERROR");
    }
  };

  return (
    <div className="bg-[#EDEDED] w-full md:w-3/4 mx-auto rounded-xl my-10 py-8 px-4">
  <h1 className="font-bold text-center text-2xl md:text-3xl">Get in Touch</h1>
  <form onSubmit={handleSubmit}>
    <div className="w-full md:w-4/5 mx-auto">
      <div className="flex flex-col md:flex-row mt-8 md:justify-between gap-4">
        <div className="w-full md:w-[47%]">
          <p className="text-sm md:text-base">Your Name*</p>
          <input
            type="text"
            name="name"
            placeholder="Write your name here"
            className="w-full bg-white py-3 px-5 shadow-lg rounded-lg mt-2"
            required
          />
        </div>
        <div className="w-full md:w-[47%]">
          <p className="text-sm md:text-base">Your Email*</p>
          <input
            type="email"
            name="email"
            placeholder="Write email address here"
            className="w-full bg-white py-3 px-5 shadow-lg rounded-lg mt-2"
            required
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-8 md:justify-between gap-4">
        <div className="w-full md:w-[47%]">
          <p className="text-sm md:text-base">Phone*</p>
          <input
            type="tel"
            name="phone"
            placeholder="Write your phone here"
            className="w-full bg-white py-3 px-5 shadow-lg rounded-lg mt-2"
          />
        </div>
        <div className="w-full md:w-[47%]">
          <p className="text-sm md:text-base">Subject*</p>
          <input
            type="text"
            name="subject"
            placeholder="What's it about?"
            className="w-full bg-white py-3 px-5 shadow-lg rounded-lg mt-2"
            required
          />
        </div>
      </div>
      <div className="mt-8">
        <p className="text-sm md:text-base">Your Message*</p>
        <textarea
          name="message"
          placeholder="Write your message here"
          className="w-full bg-white py-3 h-48 px-5 shadow-lg rounded-lg mt-2"
          required
        />
      </div>
    </div>
    <div className="flex justify-center mt-5">
      <button
        type="submit"
        className="bg-[#D8B56D] text-white py-3 px-10 md:px-32 rounded-full w-full md:w-auto"
      >
        Submit
      </button>
    </div>
    {status === "SUCCESS" && (
      <p className="text-center text-green-600 mt-5">
        Message sent successfully!
      </p>
    )}
    {status === "ERROR" && (
      <p className="text-center text-red-600 mt-5">
        Oops! There was an error.
      </p>
    )}
  </form>
</div>

  );
};

export default ContactForm;
