import React, { useEffect } from "react";
import About1 from "../Images/About1.png";
import About2 from "../Images/About2.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../App.css";

const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col md:flex-row md:w-11/12 md:mx-auto items-start mt-20 p-4 md:p-6 gap-6">
  {/* Left Section - Images */}
  <div className="flex-1 ">
    <img src={About1} alt="Clinic Interior" className="rounded-lg  w-full  mb-4" />
   
  </div>

  {/* Right Section - Text Content */}
  <div className="flex-1 flex flex-col space-y-4 md:pl-8">
    <p className="text-[#C69148] font-bold text-sm md:text-base uppercase">Welcome to</p>
    <h2 className="text-3xl mt-3 md:text-3xl font-bold text-[#000]">POINTX REALTY</h2>
    <h3 className="text-xl md:text-2xl font-semibold text-gray-800">Helping You Find Your Place in Dubai</h3>
    <p className="text-sm md:text-base text-gray-700">
    With extensive experience in the USA and UK, we are now bringing our real estate innovation to Dubai. Experts in investments and renovations, we uncover hidden gems in both commercial and residential sectors. Welcome to our collaborative world of innovation and opportunity in real estate excellence. Let's embark on a journey to redefine the landscape of property investment.
    </p>

    <div className="space-y-4">
      <div className="flex items-start">
        {/* <img src={Icon1} alt="Expert Care Icon" className="h-6 w-6 mr-3" /> */}
        <p className="text-sm md:text-base">
          <strong>Expert Knowledge and Personalized Service:
          </strong><br /> <br/>
          With an experienced team that understands Dubai’s unique market, PointX provides tailored advice to meet each client’s needs. From market trends to prime locations, our expertise enables us to help clients navigate their real estate journey with ease and confidence.
        </p>
      </div>
      <div className="flex items-start">
        {/* <img src={Icon2} alt="Affordable Pricing Icon" className="h-6 w-6 mr-3" /> */}
        <p className="text-sm md:text-base">
          <strong>Diverse Portfolio Across Prime Locations:
          </strong><br /><br />
          Our portfolio features a range of high-quality residential and commercial properties across Dubai’s iconic neighborhoods. We offer a variety of options that cater to different tastes, lifestyles, and budgets, ensuring you find the perfect property that meets your specific goals.
        </p>
      </div>
    </div>

    {/* Contact Button */}
    <div className="pt-6">
      <Link
        to="/about#top"
        className="px-6 py-2 bg-[#D8B56D] mt-10 text-white text-lg font-semibold rounded-full hover:bg-[#c18e32]"
      >
LEARN MORE
      </Link>
    </div>
  </div>
</div>

  );
};

export default AboutUs;
