import React from "react";
import location from "../Images/adress.png";
import mailadress from "../Images/mailadress.png";
import headphone from "../Images/whatsapp.png";
import call from "../Images/call.png";

const AdressBox = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-between w-full lg:w-3/4 my-10 mx-auto gap-6 lg:gap-0">
      <div className="bg-[#EDEDED] w-full lg:w-[33%] rounded-xl lg:rounded-l-xl py-7 flex flex-col justify-between items-center p-4 lg:p-3 h-auto lg:h-64">
        <div className="flex flex-col items-center">
          <img src={location} alt="Location Icon" className="w-12 lg:w-10" />
          <h1 className="font-semibold mt-4 text-xl lg:text-2xl">Our Address</h1>
        </div>
        <p className="text-[#333333] font-light text-lg lg:text-xl text-center px-2 lg:px-0">
          1908 DAMAC Smart Heights - Barsha Heights
        </p>
      </div>
      <div className="bg-[#EDEDED] w-full lg:w-[33%] py-7 flex flex-col justify-between items-center p-4 lg:p-3 h-auto lg:h-64">
        <img src={mailadress} alt="Email Icon" className="w-12 lg:w-16" />
        <h1 className="font-semibold text-xl lg:text-2xl">Email Us</h1>
        <p className="text-[#333333] font-light text-lg lg:text-xl text-center px-2 lg:px-0">
          marketing@pointxrealty.com
        </p>
      </div>
      <div className="bg-[#EDEDED] w-full lg:w-[33%] py-7 flex rounded-xl lg:rounded-r-xl flex-col justify-between items-center p-4 lg:p-3 h-auto lg:h-64">
        <img className="w-8 lg:w-12" src={headphone} alt="Headphone Icon" />
        <h1 className="font-semibold text-xl lg:text-2xl">Call Us</h1>
        <p className="text-[#333333] items-center font-light flex text-lg lg:text-xl text-center">
          <img className="mr-3 w-5" src={call} alt="Call Icon" /> +971588098415
        </p>
        <p className="text-[#333333] items-center font-light flex text-lg lg:text-xl text-center">
          <img className="mr-3 w-5" src={call} alt="Call Icon" /> +13034781109
        </p>
        <p className="text-[#333333] items-center font-light flex text-lg lg:text-xl text-center">
          <img className="mr-3 w-5" src={call} alt="Call Icon" /> +447843424959
        </p>
      </div>
    </div>
  );
};

export default AdressBox;
