import React, { useEffect } from "react";
import Mission from "../Images/Mission.png";
import Whowe from "../Images/Whowe.png";
import WhyDubai from "../Images/WhyDubai.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../App.css";

const Missions = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="w-[90%] mt-24 mx-auto">
      <div className="flex flex-col lg:flex-row justify-between items-center mb-12">
        <div
          data-aos="fade-right"
          data-aos-duration="400"
          className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:pl-16"
        >
          <h1 className="font-bold mb-4 text-2xl lg:text-3xl">Our Mission</h1>
          <p className="text-base lg:text-base">
            Our mission at PointX Realty is to assist you in finding your dream
            property whether it’s a home away from home, a vacation property or
            an investment. We offer a unique blend of global expertise and local
            insight to ensure your success. Driven by our passion for
            collaboration, innovation, and client satisfaction, our dedicated
            team is committed to providing you with an exceptional experience
            and complete transparency throughout your real estate journey. Our
            focus is clear: to deliver exceptional service tailored to your
            needs. Whether you're searching for your forever home or seeking a
            smart investment opportunity, we're here to offer guidance every
            step of the way.
          </p>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="w-full lg:w-1/2"
        >
          <img
            src={Mission}
            alt="Mission"
            className="w-10/12 h-auto  mx-auto lg:ml-10"
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row-reverse justify-between items-center mb-12">
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:pr-0"
        >
          <h1 className="font-bold mb-4 text-2xl lg:text-3xl">Who We Are</h1>
          <p className="text-base lg:text-base">
            Based in London, England, Denver Colorado, and now Dubai, UAE, we
            are collaborative innovators deeply entrenched in the real estate
            sector. With a comprehensive presence, we excel in identifying
            lucrative investment opportunities and leading ambitious renovation
            projects. Our operations are distinguished by our sharp eye for
            potential, meticulously uncovering hidden gems and prime assets. We
            leverage our analytical prowess to pinpoint exceptional investment
            prospects, spanning both commercial and residential sectors. What
            distinguishes us is not only our expertise but also our
            collaborative ethos, as we believe in the power of partnerships to
            unlock the full potential of every project. Welcome to our world,
            where innovation converges with opportunity, ushering in a new era
            of real estate excellence.
          </p>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="400"
          className="w-full lg:w-1/2 lg:mr-10"
        >
          <img
            src={Whowe}
            alt="Who we are"
            className="w-10/12 h-auto   mx-auto "
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-between items-center">
        <div
          data-aos="fade-right"
          data-aos-duration="400"
          className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:pl-16"
        >
          <h1 className="font-bold mb-4 text-2xl lg:text-3xl">Why Dubai</h1>
          <p className="text-base lg:text-base">
            Dubai's allure as an investment haven lies in its enchanting blend
            of strategic location, flourishing economy, and visionary
            development. Nestled at the crossroads of the world, this
            cosmopolitan gem beckons investors with its promise of boundless
            opportunities and unparalleled luxury. From its iconic skyline
            adorned with architectural marvels to its vibrant cultural scene and
            world-class amenities, Dubai embodies the epitome of modern
            opulence.
          </p>
          <button className="bg-[#D8B56D] text-white uppercasebg-black text-white py-3 px-5 mt-4 rounded-lg">
            <Link to={"/whydubai#top"}> Learn More</Link>
          </button>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="w-full lg:w-1/2"
        >
          <img
            src={WhyDubai}
            alt="Why Dubai"
            className="w-10/12 h-auto  mx-auto lg:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default Missions;
