import React, { useEffect, useState } from "react";
import Image1a from "./1a.png";
import Image1 from "./1.png";
import Image2a from "./2a.png";
import Image2 from "./2.png";
import Image3a from "./3a.png";
import Image3 from "./3.png";
import Image4a from "./4a.png";
import Image4 from "./4.png";
import Image5a from "./5a.png";
import Image5 from "./5.png";
import Image6a from "./6a.png";
import Image6 from "./6.png";
import Image7a from "./7a.png";
import Image7 from "./7.png";
import Image8a from "./8a.png";
import Image8 from "./8.png";
import Image9a from "./9a.png";
import Image9 from "./9.png";
import Image10a from "./10a.png";
import Image10 from "./10.png";
import Image11a from "./11a.png";
import Image11 from "./11.png";
import Image12a from "./12a.png";
import Image12 from "./12.png";
import Image13a from "./13a.png";
import Image13 from "./13.png";
import Image14a from "./14a.png";
import Image14 from "./14.png";
import Image15a from "./15a.png";
import Image15 from "./15.png";

import Bed from "./Bed.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const PRojectCards = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const cardData = [
    {
      image: Image1,
      hoverImage: Image1a,
      title: "Samana Portofino ",
      detail1: " Flexible payment plans Starting from AED 676,000",
      detail2: "1 to 2 Bedrooms",
      button1Text: "Back",
      button2Text: "Details",
      Link: "/Portofino#top",
      Link2: "/developers#top",
    },
    {
      image: Image2,
      hoverImage: Image2a,
      title: "Samana Manhattan 2",
      detail1: "Flexible payment plans varied based on unit size & type",
      detail2: "1 to 2 Bedrooms",
      button1Text: "Back",
      button2Text: "Details",
      Link2: "/developers#top",
      Link: "/Manhattan2#top",
    },
    {
      image: Image3,
      hoverImage: Image3a,
      title: "Samana Manhattan  ",
      detail1: "40/60 Payment Plan 7mn-11mn AED",
      detail2: "1 to 4 Bedrooms",
      button1Text: "Back",
      button2Text: "Details",
      Link2: "/developers#top",
      Link: "/Manhattan#top",
    },
    {
      image: Image4,
      hoverImage: Image4a,
      title: "Samana Lake Views",
      detail1: "Flexible payment plans varied based on unit size & type",
      detail2: "1 to 2 Bedrooms",
      button1Text: "Back",
      button2Text: "Details",
      Link2: "/developers#top",
      Link: "/Lake#top",
    },

    {
      image: Image5,
      hoverImage: Image5a,
      title: "Samana Barari Views 2",
      detail1: "Flexible payment plans varied based on unit size & type",
      detail2: "1 to 3 Bedrooms",
      button1Text: "Back",
      button2Text: "Details",
      Link2: "/developers#top",
      Link: "/Barari#top",
    },
  ];

  const [currentImages, setCurrentImages] = useState(
    cardData.map((card) => card.image)
  );

  const handleMouseEnter = (index, hoverImage) => {
    const newImages = [...currentImages];
    newImages[index] = hoverImage;
    setCurrentImages(newImages);
  };

  const handleMouseLeave = (index, image) => {
    const newImages = [...currentImages];
    newImages[index] = image;
    setCurrentImages(newImages);
  };

  return (
    <section className="grid grid-cols-1 w-11/12 mx-auto sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 p-4">
      {cardData.map((card, index) => {
        const rowIndex = Math.floor(index / 3);
        const animation = rowIndex % 2 === 0 ? "fade-right" : "fade-left";

        return (
          <div
            key={index}
            data-aos={animation}
            data-aos-duration="800"
            className="bg-white ml-12 w-[396px] shadow-md rounded-md overflow-hidden"
            onMouseEnter={() => handleMouseEnter(index, card.hoverImage)}
            onMouseLeave={() => handleMouseLeave(index, card.image)}
          >
            <Link to={card.Link}>
              <img
                src={currentImages[index]}
                alt="Description"
                className="object-cover"
              />
            </Link>
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2 text-center">
                {card.title}
              </h2>
              <div className="flex justify-between mb-4">
                <p className="text-sm w-2/5">{card.detail1}</p>
                <p className="text-sm w-1/3 flex items-center">
                  <img className="mr-3" src={Bed} alt="" /> {card.detail2}
                </p>
              </div>
              <div className="flex justify-center gap-10">
                <Link
                  to={card.Link2}
                  className="bg-[#FFFFFF] border border-black text-black py-2 px-4 rounded"
                >
                  {card.button1Text}
                </Link>
                <Link
                  to={card.Link}
                  className="bg-black text-white py-2 px-6 rounded"
                >
                  {card.button2Text}
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default PRojectCards;
