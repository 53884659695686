import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const FilterBar = () => {
  const [location, setLocation] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (location === "Dubai production city") {
      navigate("/Samana");
    } else if (location === "Jumeirah Village Circle") {
      navigate("/Samana");
    } else if (location === "Dubai Islands") {
      navigate("/Nakheel");
    } else if (location === "DAMAC Hills 2") {
      navigate("/Damac");
    } else if (location === "Al Sufouh") {
      navigate("/Damac");
    } else if (location === "Business bay") {
      navigate("/Damac");
    } else if (location === "Ras Al Khaimah") {
      navigate("/Aldar");
    } else if (location === "Dubailand") {
      navigate("/Aldar");
    }
  };

  return (
    <div className="w-11/12 bg-[#D4D9E833]/70 mx-auto p-6 mt-10 rounded-lg shadow-lg">
      <div className="flex justify-center items-center">
        <h2 className="text-2xl font-bold text-[#000] mr-2">
          Find Your Perfect Home
        </h2>
        <FaSearch className="text-xl text-[#000] ml-3 mt-1" />
      </div>
      <div className="grid grid-cols-1 mt-5 gap-4 md:grid-cols-3 lg:grid-cols-5">
        <div>
          <select
            className="block w-full text-black mt-1 p-2 border border-gray-300 rounded"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            <option value="">Select Location</option>
            <option value="Dubai production city">Dubai production city</option>
            <option value="Jumeirah Village Circle">
              Jumeirah Village Circle
            </option>
            <option value="Dubai Islands">Dubai Islands</option>
            <option value="DAMAC Hills 2">DAMAC Hills 2</option>
            <option value="Al Sufouh">Al Sufouh</option>
            <option value="Business bay">Business bay</option>
            <option value="Ras Al Khaimah">Ras Al Khaimah</option>
            <option value="Dubailand">Dubailand</option>
          </select>
        </div>
        <div>
          <select className="block w-full text-black mt-1 p-2 border border-gray-300 rounded">
            <option value="">Select Price Range</option>
            <option value="0-1000000">AED0 - AED10,00,000</option>
            <option value="1000000-5000000">AED10,00,000 - AED50,00,000</option>
            <option value="5000000-50000000">
              AED50,00,000 - AED50,000,000
            </option>
          </select>
        </div>
        <div>
          <select className="block w-full text-black mt-1 p-2 border border-gray-300 rounded">
            <option value="">Select Category</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="land">Land</option>
          </select>
        </div>
        <div>
          <select className="block text-black w-full mt-1 p-2 border border-gray-300 rounded">
            <option value="">Select Property Type</option>
            <option value="house">Villa</option>
            <option value="apartment">Apartment</option>
          </select>
        </div>
        <button
          className="w-full bg-[#D8B56D] text-white py-2 rounded transition duration-300"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default FilterBar;
