import React, { useEffect } from "react";
import Prop1 from "../Images/Prop1.png";
import Prop2 from "../Images/Prop2.png";
import Prop3 from "../Images/Prop3.png";
import Area from "../Images/area.png";
import Beds from "../Images/beds.png";
import vector from "../Images/vector.png";
import Baths from "../Images/baths.png";
import Menu from "../Images/menu.png";
import Cars from "../Images/car.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../App.css";

const Featured = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="bg-[#f5f5f5] pb-8">
      
      <h1 className="text-center text-2xl md:text-4xl pt-10">Featured Properties</h1>
      <div className="w-11/12 mx-auto flex flex-col md:flex-row p-6 gap-12">
        {/* Property Card 1 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/tilalAlGhaf#top"}>
              <img className="w-full h-64 object-cover" src={Prop1} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/tilalAlGhaf#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Tilal Al Ghaf
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom Villa in Tilal Al Ghaf
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">605 /6,515 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">6 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Cars} alt="Feature 2" />
                <p className="text-sm text-gray-900">3 Garage</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">7 Bathrooms</p>
              </div>
            </div>
            <Link to={"/tilalAlGhaf#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>

        {/* Property Card 2 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/zorah#top"}>
              <img className="w-full h-64 object-cover" src={Prop2} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/zorah#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Al Zorah
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              Six bedroom villa in Alzorah
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Ajman
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">5800 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">4 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Cars} alt="Feature 2" />
                <p className="text-sm text-gray-900">3 Garage</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">6 Bathrooms</p>
              </div>
            </div>
            <Link to={"/zorah#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>

        {/* Property Card 3 */}
        <div
          data-aos="fade-right"
          data-aos-duration="800"
          className="overflow-hidden shadow-lg border rounded-lg w-full md:w-1/3"
        >
          <div className="relative">
            <Link to={"/DownTown#top"}>
              <img className="w-full h-64 object-cover" src={Prop3} alt="Property" />
            </Link>
            <button className="bg-[#D8B56D] text-white uppercaseabsolute top-4 left-4  text-white px-2 py-1 rounded">
              For Sale
            </button>
          </div>
          <div className="pt-4 px-4">
            <Link
              to={"/DownTown#top"}
              className="font-bold text-xl text-center mb-2 block"
            >
              Burj Lake Hotel
            </Link>
            <p className="text-gray-700 text-center text-base mb-4">
              2 Bedroom Apartment in The Address Downtown 
            </p>
            <p className="flex justify-center text-[#969696]">
              <img src={vector} className="mr-2" alt="" />
              Dubai
            </p>
            <hr className="my-4" />
            <div className="flex justify-between">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Menu} alt="Feature 1" />
                <p className="text-sm text-gray-900">120/1,292 SQFT</p>
              </div>
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Beds} alt="Feature 3" />
                <p className="text-sm text-gray-900">2 Bedrooms</p>
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <img className="rounded-full mr-2" src={Baths} alt="Feature 4" />
                <p className="text-sm text-gray-900">1 Bathroom</p>
              </div>
            </div>
            <Link to={"/DownTown#top"}>
              <button className="bg-[#D8B56D] uppercase text-xl mt-14 rounded-xl text-white font-semibold px-4 py-4 w-full animate-fadeIn">
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Link to={"/offplan#top"}>
              <button className="bg-[#D8B56D]  uppercasebg-[#D8B56D] uppercase  text-white font-bold py-2 md:py-4 px-4 flex justify-self-center items-center justify-center rounded mt-4 w-1/5">
                View More
              </button>
            </Link>
    </div>
  );
};

export default Featured;
