import React, { useState } from "react";
import CustomSlider from "../HomePageComps/CustomSlider";
import AboutUs from "../HomePageComps/Aboutus";
import Featured from "../HomePageComps/Featured";
import OffPlanProperties from "../HomePageComps/OffPlan";
import Communities from "../HomePageComps/Communities";
import Partners from "../HomePageComps/Partners";
import Footer from "../WhyDubaiComps/Footer";
import AnimatedSection from "./AnimatedSection"; // Update the path as needed

const HomePages = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <CustomSlider />

      <AboutUs />
      <Featured />
      {/* <OffPlanProperties /> */}
      <Communities />
      <Partners />
      <Footer />
    </div>
  );
};

export default HomePages;
