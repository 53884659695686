import React, { useState } from "react";
import Hero from "./Hero";
import DevCards from "./DevCards";
import Footer from "../WhyDubaiComps/Footer";
import useScrollToTop from "../Scroll";

const Developers = () => {
  useScrollToTop();
  const [searchValue, setSearchValue] = React.useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div id="top">
      <Hero onSearch={handleSearch} />
      <DevCards searchValue={searchValue} />
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default Developers;
