import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

const AnimatedSection = ({ children, animation }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={classNames({
        "opacity-0": !inView,
        "animate-fadeIn": inView && animation === "fadeIn",
        "animate-fadeInUp": inView && animation === "fadeInUp",
        "animate-fadeInRight": inView && animation === "fadeInRight",
        "animate-fadeInLeft": inView && animation === "fadeInLeft",
        "animate-fadeInDown": inView && animation === "fadeInDown",
        "animate-fadeOut": inView && animation === "fadeOut",
      })}
    >
      {children}
    </div>
  );
};

export default AnimatedSection;
