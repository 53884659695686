import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../WhyDubaiComps/Footer";
import Logo from "../../Images/Logo.png";
import Detail1 from "../../Images/Detail6.png";
import blogcard1 from "../../Images/blogcard1.png";
import blogcard2 from "../../Images/blogcard2.png";
import useScrollToTop from "../../Scroll";
import blogcard3 from "../../Images/blogcard3.png";
const Card = ({ image, title, text }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden p-4 flex flex-col justify-between h-full">
    <div>
      <img
        className="w-full h-48 object-cover rounded-md"
        src={image}
        alt={title}
      />
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="text-gray-600 mt-2">{text}</p>
    </div>
  </div>
);
const Page6 = () => {
  useScrollToTop();

  const cards = [
    {
      image: blogcard1,
      title:
        "Dubai Real Estate Market Witnesses a 27% Surge in Transactions During February",
      text: "The most fascinating aspect of this tale of growth and development was witnessed in Dubai’s real estate market in February this year",
    },
    {
      image: blogcard2,
      title: "Dubai Leads Global Prime Residential Market Growth in 2024",
      text: "The study on the share of global prime residential market growth in the year 2024 has revealed that Dubai has outperformed all competitors in 2023.",
    },
    {
      image: blogcard3,
      title:
        "Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values",
      text: "In January 2024, there was an increase in the price of apartments in Dubai, which showed the highest growth in the last ten years, according to ValuStrat Price Index (VPI).",
    },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <div className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </div>
            <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-slate-300 text-white py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
          <div className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </div>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        {menuOpen && (
          <ul className="bg-slate-300 text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>  <div className=" mx-auto flex items-center flex-col mt-32">
        <img src={Detail1} alt="" />
        <div className="w-10/12">
          <p className="mt-20 ">
            In the global comparative real estate market, another significant
            attribute of Dubai is the tax regime, which is still highly
            beneficial and still attracting international investors. Contrary to
            most other leading cities for international buy-to-let investments
            like London and Singapore, there are no capital or other annual
            charges or gains tax on property purchases in Dubai, making it all
            the more appealing when it comes to creating optimum capital
            appreciation and rental yields in the long term. Much credit for
            such success should go to this tax-free zone that has been
            instrumental to Dubai’s growth in the real estate sector over the
            last two decades. Investor interest is attracted by the ability to
            receive high returns on their investment, capital gains on
            properties, and the versatility of properties available in the
            emirate, making it one of the most preferred real estate markets for
            investors globally.
          </p>

          <h2 className="my-5 font-bold">
            Understanding the Basics of Property Tax in Dubai
          </h2>
          <p className="my-5 ">
            While other global financial hubs hold considerable characteristics
            in common with Dubai, the emirate distinguishes itself in its
            refusal to collect taxes on property or lease income generated from
            properties located within its jurisdiction. This distinctive policy
            has been useful in determining the emirate's real estate business
            growth and in attracting investors globally. For instance, Singapore
            and Hong Kong charge stamp duties of up to 30% and 60% respectively
            while cities in Europe such as London and Paris charge up to 12% and
            6% respectively. Compared to these cities, Dubai has no charges for
            stamp duty fees on property. It also lacks capital gains and yearly
            taxes in comparison to other jurisdictions that can range from 16%
            to 28% and may include wealth taxes such as those in the UK and
            France. Additionally, income tax has never been a policy within the
            UAE, making landlords receive 100% of rental revenues.
          </p>

          <h2 className="my-5 font-bold">
            Advantages of Dubai’s Property Tax System for Investors
          </h2>
          <p className="my-5 ">
            The following is an outline of the benefits of the property tax
            system in Dubai. High rental yields with no tax deduction benefit
            property owners from paying annual property taxes, while the lack of
            property depreciation makes ongoing costs low. Additionally, the
            cost of transactions in the Dubai real estate sector is known to be
            lower, and this includes operation fees for transfers of real estate
            ownership and registrations of properties. No capital gains tax is
            felt by investors, who do not have to feel pressured to pay taxes
            any time they sell their properties to make additional earnings.
            These benefits allow the investor to receive the maximum out of the
            profits from investments in the market, where capital gains have
            risen by 19%. Worrying about short- or medium-term capital gains
            taxes is not necessarily a relevant factor for long-term value
            investors; Dubai offers no upfront taxes and unlimited tax-free
            treatment of rental income, making the American investor, for
            instance, quite unique and an exciting property market for wealth
            preservation and accumulation.
          </p>

          <h2 className="my-5 font-bold">
            Understanding Fees Associated with Buying Property in Dubai
          </h2>
          <p className="my-5 ">
            However, fees are charged when investing in real estate properties
            in Dubai, even though it is a tax-free country. The first is the
            transfer fee, and the second is the registration fee. In some cases,
            there are additional fees payable to the seller.
          </p>
          <p className="my-5 ">
            Transfer Fee: The purchaser is expected to provide a transfer fee
            equal to four percent of the purchase price at the time of
            completing a property purchase, plus AED 540 as an administrative
            charge.
          </p>
          <p className="my-5 ">
            Registration Fee: Consumers also have to pay the registration fee,
            which is also chargeable depending on the value of the property.
            Hence, for properties of value up to AED 500,000, the registration
            fee is AED 2000; for properties above and limited to AED 500,000,
            the registration fee is AED 4000. Also, the new owners of the
            property are required to procure an ownership certificate, which
            costs a fee of AED 250.
          </p>
          <p className="my-5 ">
            Although these fees are relatively lower compared to many other
            countries, they are essential to consider alongside Dubai's tax-free
            income policy, positioning the city as one of the most attractive
            property investment hubs globally.
          </p>

          <h2 className="my-5 font-bold">
            Navigating the Buying Process and Property Tax
          </h2>
          <p className="my-5 ">
            A simple and efficient process favors foreign investors in owning
            property in Dubai, as seen in the following section. The process
            usually includes identification of the property, entry into
            negotiations with the seller, and finally entering into a legal
            formalities sale contract that contains the details of the agreement
            entered into between the two.
          </p>
          <p className="my-5 ">
            For international buyers looking for an easy and straightforward
            buying process in a prime location, the crystal-clear structure of
            the legal system in Dubai offers this advantage. Property rights and
            ownership are well defined in the city’s real estate market, which
            keeps investors confident when investing in a property to let.
            Additionally, the procedures of acquisition as well as the
            registration process are eased, especially through the Dubai Land
            Department, where all the costs involved are made clear to the
            investors.
          </p>
          <p className="my-5 ">
            Comprehensive resources are readily available to guide investors
            through the property buying process in Dubai and leverage its
            tax-free system to their advantage.
          </p>

          <h2 className="my-5 font-bold">
            Property Investment in Dubai: Less Tax, More Wealth
          </h2>
          <p className="my-5 ">
            Dubai presents overseas property investors with an unparalleled
            value proposition. By eliminating capital gains taxes, recurring
            annual levies, and transaction duties, the emirate encourages
            portfolio growth and unlocks high rental income potential unmatched
            by other global hubs. With its nominal purchase costs,
            investor-friendly legal framework, and abundance of property
            management services, Dubai offers a seamless investment experience
            for foreigners. As the city solidifies its position as a lifestyle
            and business destination for generations to come, its real estate
            foundations remain robust. For newcomers seeking a profitable,
            efficient, and tax-free home for their investments, Dubai stands
            alone as an investor's paradise primed to deliver exceptional
            returns.
          </p>
        </div>
      </div>
      <div className="w-4/5 my-10 mx-auto ">
        <h1 className="font-bold text-3xl underline">Related Articles</h1>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 max-w-screen-2xl">
            {cards.map((card, index) => (
              <Card
                key={index}
                image={card.image}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page6;
