import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../WhyDubaiComps/Footer";
import Logo from "../../Images/Logo.png";
import Detail1 from "../../Images/Detail7.png";
import blogcard1 from "../../Images/blogcard1.png";
import blogcard2 from "../../Images/blogcard2.png";
import useScrollToTop from "../../Scroll";
import blogcard3 from "../../Images/blogcard3.png";
const Card = ({ image, title, text }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden p-4 flex flex-col justify-between h-full">
    <div>
      <img
        className="w-full h-48 object-cover rounded-md"
        src={image}
        alt={title}
      />
      <h2 className="text-xl font-bold mt-4">{title}</h2>
      <p className="text-gray-600 mt-2">{text}</p>
    </div>
  </div>
);
const Page7 = () => {
  useScrollToTop();

  const cards = [
    {
      image: blogcard1,
      title:
        "Dubai Real Estate Market Witnesses a 27% Surge in Transactions During February",
      text: "The most fascinating aspect of this tale of growth and development was witnessed in Dubai’s real estate market in February this year",
    },
    {
      image: blogcard2,
      title: "Dubai Leads Global Prime Residential Market Growth in 2024",
      text: "The study on the share of global prime residential market growth in the year 2024 has revealed that Dubai has outperformed all competitors in 2023.",
    },
    {
      image: blogcard3,
      title:
        "Dubai's Real Estate Market Achieves Decade-High Growth in Apartment Values",
      text: "In January 2024, there was an increase in the price of apartments in Dubai, which showed the highest growth in the last ten years, according to ValuStrat Price Index (VPI).",
    },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      {/* Desktop Navbar */}
   <nav className="hidden md:flex flex-col w-full">
        {/* Top Bar - Gold Background */}
        <div className="bg-[#D8B56D] flex py-3 w-full">
          <div className="container ml-auto mr-10 w-11/12 flex justify-end items-center">
            <ul className="flex text-sm md:text-xl text-white space-x-6">
              <li>
                <Link to="/" className="hover:font-bold">
                  HOME
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:font-bold">
                  ABOUT
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:font-bold">
                  SERVICES
                </Link>
              </li>
              <li>
                <Link to="/blogs" className="hover:font-bold">
                  BLOGS
                </Link>
              </li>
             
              <li>
                <Link to="/contact" className="hover:font-bold">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section - Logo and Secondary Links */}
        <div className="bg-white py-4 w-full border-b">
          <div className="container mx-auto w-10/12 flex justify-between items-center">
            <div className="flex items-center">
              <img className="h-10 w-10 md:h-20 md:w-20 mr-4" src={Logo} alt="Logo" />
            </div>
            <ul className="flex text-sm md:text-xl space-x-6 text-black">
              <li>
                <Link to="/communities" className="hover:font-bold">
                  COMMUNITIES
                </Link>
              </li>
              <li>
                <Link to="/developers" className="hover:font-bold">
                  DEVELOPERS
                </Link>
              </li>
              <li>
                <Link to="/offplan" className="hover:font-bold">
                  LISTINGS
                </Link>
              </li>
            </ul>
            <div>
              <Link to="/contact" className="bg-[#D8B56D] text-white text-lg font-bold py-2 px-6 rounded-full hover:bg-opacity-90">
                FIND AN INVESTMENT
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <nav className="md:hidden bg-slate-300 text-white py-2 w-full">
        <div className="container mx-auto w-11/12 flex justify-between items-center">
          <div className="flex items-center">
            <img className="h-10 w-10 mr-4" src={Logo} alt="Logo" />
          </div>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        {menuOpen && (
          <ul className="bg-slate-300 text-black">
            <li className="border-b border-gray-400">
              <Link to="/" className="block px-4 py-2">
                Home
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/about" className="block px-4 py-2">
                About Us
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/developers" className="block px-4 py-2">
                Developers
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/communities" className="block px-4 py-2">
                Communities
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/services" className="block px-4 py-2">
                Services
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/blogs" className="block px-4 py-2">
                Blogs
              </Link>
            </li>
            <li className="border-b border-gray-400">
              <Link to="/contact" className="block px-4 py-2">
                Contact
              </Link>
            </li>
          </ul>
        )}
      </nav>  <div className=" mx-auto flex items-center flex-col mt-32">
        <img src={Detail1} alt="" />
        <div className="w-10/12">
          <p className="mt-20 font-semibold">
            Dubai, the Jewel of the Middle East, is gradually responding to the
            allure of foreign investors who are willing to invest in this area
            as well as get reasonable yields from reasonably priced real estate
            stocks. This is also worth mentioning that, in the midst of the
            global average price of $906 per square meter having ‘risen’, Dubai
            on average became $6,057 per square meter, which is relatively close
            to Singapore's $15,105/sq. m. and London's 101,598€ per sq. m.
          </p>

          <p className="my-10 font-semibold">
            The factors that have fostered interest in Dubai are its
            geographical position, tax system, and favorable policies, which, in
            turn, have placed it at the center of property investment
            internationally. Thus, the problem mentioned by Realiste is the
            absence of cheap territories. Even if investors are willing to pay a
            large amount of money for investments, there is no country that one
            would consider to be cheap and that can potentially yield good
            returns. Dubai is, however, slowly crawling out of the reemerging
            trend in the property sector due to the relatively cheap prices for
            property in comparison to other countries, coupled with government
            incentives for the market.
          </p>

          <p className="my-10 font-semibold">
            Although Dubai's rising unit prices raise concerns, the market
            remains healthy and dynamic, offering comparatively affordable
            options. Realiste indicates that Dubai is in competition with
            established real estate giants such as London, New York, and
            Singapore, with the potential to double its real estate prices in
            the next five years.
          </p>

          <p className="my-10 font-semibold">
            Singapore has been established as a stable nation with favorable
            conditions for carrying out business and has always been popular as
            an attractive investment territory in Asia. But the Realiste study
            shows that Singapore currently ranks as one of the most expensive
            for real estate, with an average square meter costing an astonishing
            $15,105, and has even bypassed London.
          </p>

          <p className="my-10 font-semibold">
            London, which has an old and very fabled background and is now
            culturally so prestigious, is counted among the biggest
            international real estate arenas. However, it is very expensive and
            not within reach of most users in the market. The study indicates
            possible discounts on the London property market, setting the
            prospects that they’re predicted to fall by 1.
          </p>

          <p className="my-10 font-semibold">
            New York, being one of the world’s financial epicenters, has always
            been able to attract investors, especially those interested in
            luxury properties. However, the latest data shows the average price
            per square meter at about $4750, with house prices having dropped by
            1.8% year-over-year. This has sparked investors’ worry about a
            possible market crash due to this decline.
          </p>

          <p className="my-10 font-semibold">
            On the other hand, Dubai presents a wide variety of opportunities
            for beginners, professional buyers, and investors so that they can
            obtain more properties and have a very profitable future.
          </p>
        </div>
      </div>
      <div className="w-4/5 my-10 mx-auto ">
        <h1 className="font-bold text-3xl underline">Related Articles</h1>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 max-w-screen-2xl">
            {cards.map((card, index) => (
              <Card
                key={index}
                image={card.image}
                title={card.title}
                text={card.text}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page7;
